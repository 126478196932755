import React, { useState, useEffect, useContext } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import api, { url } from "../api";
import ReactMarkdown from "react-markdown";
import { Button, Skeleton } from "antd";
import { navigate } from "gatsby";
import { AuthContext } from "../context/GlobalContextProvider";

function Sobre() {
  const context = useContext(AuthContext);
  const [page, setPage] = useState();
  useEffect(() => {
    !page && getPage();
  }, []);

  const getPage = async () => {
    const { data } = await api.get("/sobre");
    localStorage.setItem("sobre", JSON.stringify(data));
    !page && setPage(data);
  };
  return (
    <Layout aboveFooter loading={false} titlePage="Quem Somos?">
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Quem Somos"
      />
      {context?.user && (
        <Button
          style={{ borderColor: "orange", color: "orange" }}
          danger
          onClick={() => navigate(`/edicao/sobre`)}
        >
          Editar
        </Button>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 my-4 self-center">
        <div>
          <Skeleton loading={!page}>
            <div className="mb-10">
              <ReactMarkdown
                source={page?.quemSomos}
                renderers={{
                  // eslint-disable-next-line react/display-name
                  paragraph: (props) => (
                    <p name="a" className="text-md mb-6 opacity-75" {...props} />
                  ),
                }}
              />
            </div>
          </Skeleton>
          <h2 id="missao" className="mb-3">
            Missão
          </h2>
          <Skeleton loading={!page}>
            <div>
              <ReactMarkdown
                renderers={{
                  // eslint-disable-next-line react/display-name
                  paragraph: (props) => (
                    <p
                      name="a"
                      className="text-md mb-6 opacity-75"
                      {...props}
                    />
                  ),
                }}
                source={page?.missao}
              />
            </div>
          </Skeleton>
          <br />
          <br />
          <h2 id="visao" name="visao" className="mb-3">
            Visão
          </h2>
          <Skeleton loading={!page}>
            <div>
              <ReactMarkdown
                renderers={{
                  // eslint-disable-next-line react/display-name
                  paragraph: (props) => (
                    <p
                      name="a"
                      className="text-md mb-6 opacity-75"
                      {...props}
                    />
                  ),
                }}
                source={page?.visao}
              />
            </div>
          </Skeleton>
          <br />
          <br />
          <h2 id="valores" name="valores" className="mb-3">
            Valores
          </h2>
          <Skeleton loading={!page}>
            <div>
              <ReactMarkdown
                renderers={{
                  // eslint-disable-next-line react/display-name
                  paragraph: (props) => (
                    <p
                      name="a"
                      className="text-md mb-6 opacity-75"
                      {...props}
                    />
                  ),
                }}
                source={page?.valores}
              />
            </div>
          </Skeleton>
          <h2 id="compromissoSocial" name="compromissoSocial" className="mb-3">
            Compromisso social
          </h2>
          <Skeleton loading={!page}>
            <div>
              <ReactMarkdown
                renderers={{
                  // eslint-disable-next-line react/display-name
                  paragraph: (props) => (
                    <p
                      name="a"
                      className="text-md mb-6 opacity-75"
                      {...props}
                    />
                  ),
                }}
                source={page?.compromissoSocial}
              />
            </div>
          </Skeleton>
        </div>
        <img className="lg:w-full" src={`${url}${page?.imagem?.url}`} />
      </div>
    </Layout>
  );
}

export default Sobre;
